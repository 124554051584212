.mobile,
button.mobile.learn-more.pdf-download {
  display: none;
}
.orientationMessage {
  display: none;
}
@media (max-width: 900px) and (min-width: 768px) {
  .the-fumigation-process .lower {
    padding: 10px;
    height: 300px;
  }
  .the-fumigation-process .lower h2 {
    height: 88px;
  }
  button.learn-more.pdf-download {
    margin-left: 30px;
    margin-top: 30px;
  }
}
@media (max-width: 1400px) and (min-width: 768px) {
  .reveal-slider {
    max-width: 100%;
    left: 0px;
    margin-left: 0px;
  }
  .reveal-slider .right {
    padding-left: 0px;
  }
  span.cd-handle:after {
    content: '';
    position: absolute;
    background-color: #4e89a0;
    width: 5px;
    margin-top: -250px;
    margin-left: 15px;
    z-index: -1;
  }
  .reveal-slider {
    height: 80%;
    margin-top: 6%;
    top: 0px;
    width: 90%;
    margin-left: 5% !important;
  }
}
@media (max-width: 900px) and (min-width: 768px) {
  .the-fumigation-process .lower h2 {
    font-size: 16px;
    line-height: 20px;
  }
  #section5 .col-sm-3 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .third h3 {
    font-size: 14px;
  }
  .third ul {
    height: auto !important;
  }
}
/* Mobile View */

@media (max-width: 767px) {
  .mobile {
    display: block;
  }
  /* Hide elements */

  .the-svg,
  .down-arrow-container {
    display: none;
  }
  /* End hidden elements */
  /* Template styles */

  .menu {
    width: 100%;
    right: -100%;
  }
  div#parallax,
  .parallax-layer {
    height: 110% !important;
  }
  .parallax-layer img {
    width: auto;
    min-height: 110%;
    max-width: 150%;
  }
  .fp-tableCell {
    vertical-align: top;
  }
  .bg-panel {
    display: none !important;
  }
  .blue-panel.bg-b.bg-panel {
    display: block !important;
    top: 0px !important;
    left: 0px !important;
    bottom: inherit !important;
  }
  .outer-drop.mobile.wf-outer {
    padding: 20px 10px 20px 10px;
  }
  .col-lg-12 {
    padding-top: 30px;
    text-align: center;
  }
  div#section0 {
    padding-top: 32px;
  }
  .menu-toggle p {
    display: none;
  }
  .brand {
    left: 20px !important;
  }
  /* Global Styles */
  /* Hero Content Styles */

  .headline {
    max-width: 300px;
    margin: 0 auto;
    padding: 40px 0px 20px 0px;
  }
  .mob-house {
    width: 80%;
    margin: 0 auto;
    max-width: 500px;
  }
  svg.mob-circle.mobile {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .mobile.the-mob-home {
    position: relative;
  }
  .subhead {
    font-family: Erazm-Regular;
    text-transform: uppercase;
    font-size: 32px;
    letter-spacing: 2px;
    font-weight: 100;
    text-align: center;
    line-height: 32px;
    margin-top: -10px;
    padding-bottom: 30px;
  }
  .hero-content-btm {
    font-family: Erazm-Regular;
    text-align: center;
    font-size: 16px;
    letter-spacing: 1.5px;
    font-weight: 100;
    line-height: 20px;
  }
  .hero-content-btm img {
    padding: 30px 0px 30px 0px;
    max-width: 80%;
  }
  .leftOne,
  .rightOne {
    width: 48%;
    float: left;
  }
  .leftOne {
    border-right: 5px solid #fff;
    padding-right: 2%;
  }
  .rightOne {
    padding-left: 2%;
  }
  .video-comment p {
    display: block;
    font-size: 14px;
    line-height: 19px;
    height: auto;
    background: #518ba2;
    padding-bottom: 10px;
  }
  span.section-title {
    position: relative;
    top: -12px;
  }
  .video object {
    max-height: 218px;
  }
  .video-comment {
    height: auto;
    border: none;
    background-color: transparent;
  }
  /* Section Specific */
  /*Reveal Slider*/

  .reveal-slider {
    clear: both;
    display: block;
    position: relative;
    max-width: 100%;
    margin: 0px;
    padding: 10px;
    height: 340px;
    margin-bottom: 40px;
    left: 0px;
    top: 20px;
  }
  span.close-reveal-slider,
  .reveal-slider h1 br {
    display: none;
  }
  .reveal-slider .right {
    display: block;
    position: absolute;
    top: 22px;
    width: 100%;
    z-index: 5;
  }
  .reveal-slider .left {
    width: 100%;
    padding: 0px;
    margin-top: 105px;
  }
  .reveal-slider .right-inner {
    display: block;
    text-align: center;
  }
  figure.cd-image-container {
    top: 0px;
    left: 0px;
    margin: 0px !important;
    width: 100%;
  }
  span.cd-handle:after {
    height: 179px;
    margin-top: -108px;
  }
  body .reveal-slider span.cd-handle {
    opacity: 1 !important;
    display: block !important;
    visibility: visible !important;
    transform: translate3d(0, 0, 0) scale(1);
    -webkit-transition: none;
    -moz-transition: none;
  }
  /* Why Fumigate */

  #section3 h1 {
    margin-bottom: 20px;
  }
  /* How it Works*/

  div#section4 {
    padding-bottom: 30px;
  }
  #section4 .leftOne,
  #section4 .rightOne {
    border: none;
    padding: 0px;
    margin: 2%;
    width: 46%;
  }
  .the-fumigation-process .upper {
    margin-top: 20px;
  }
  .the-fumigation-process .lower {
    height: auto;
  }
  div#section6 {
    padding-top: 30px;
  }
  #section6 h1 {
    margin-bottom: 20px;
  }
  .the-fumigation-process .lower h2 {
    font-size: 16px;
    line-height: 18px;
  }
  /*How to Prepare*/

  .more-info-inner {
    padding-top: 60px;
  }
  .how-to-prepare-carousel * {
    background: none !important;
  }
  img.carousel-object {
    margin: 0 auto;
    width: 70%;
  }
  span.carousel-next,
  span.carousel-prev {
    font-size: 70px;
    opacity: 0.95;
    margin-top: 83px;
    display: block;
  }
  .how-to-prepare-carousel {
    margin-top: 30px;
    padding-bottom: 40px;
  }
  /*Possible Merge Styles*/

  .row h1,
  .reveal-slider h1 {
    font-size: 30px;
    border-bottom: 5px solid #518ba2;
    text-align: center;
    display: inline-block;
    margin: 0 auto;
    line-height: 30px;
  }
  .reveal-slider p {
    font-size: 10px;
    line-height: 12px;
    padding-top: 5px;
    border-bottom: none;
  }
  .row span.circle-mid {
    margin: 0px 10px;
    top: -5px;
  }
  .fumigation-vs-spot-treatment h2,
  .family-ready h2 {
    font-size: 14px !important;
    padding: 10px;
    line-height: 20px;
  }
  img.headline.mobile,
  .subhead.mobile {
    max-width: 95%;
    margin: 0 auto;
    padding-bottom: 0px;
  }
  .subhead.mobile {
    padding-bottom: 10px;
  }
  button.learn-more {
    width: 166px;
    height: 50px;
    font-size: 28px;
    position: relative;
    left: -30px;
    padding-top: 8px;
    line-height: 25px;
  }
  span.extra {
    height: 60px;
    width: 60px;
    margin-left: 72px;
  }
  /* section#parallax {
        display: none;
    } */
  body div#section0,
  body div#section1,
  body div#section4,
  body div#section7 {
    background-size: cover;
    background-blend-mode: soft-light;
  }
  .panel-color-blocks {
    display: none;
  }
  div#section0 {
    background: rgba(227, 197, 79, 0.3) url('../img/bg.jpg') no-repeat !important;
  }
  div#section1 {
    background: rgba(16, 79, 165, 0.5) url('../img/bg.jpg') no-repeat !important;
  }
  div#section4 {
    background: rgba(132, 63, 134, 0.5) url('../img/bg.jpg') no-repeat !important;
  }
  div#section7 {
    background: rgba(239, 164, 55, 0.5) url('../img/bg.jpg') no-repeat !important;
  }
  span.circle-mid {
    top: -5px;
    margin-right: 5px;
    margin-left: 5px;
  }
  .reveal-slider p {
    padding: 10px;
  }
  span.cd-handle:after {
    width: 4px;
  }
  .reveal-slider .left {
    padding-left: 11%;
  }
  .col-sm-7.video {
    left: 0px;
  }
  .col-sm-5.video-comment {
    left: 0px;
    width: 100%;
    padding: 0px;
    margin-top: -5px;
  }
  .reveal-slider p br {
    display: none;
  }
  .reveal-slider p span {
    display: inline-block;
    margin-right: 5px;
  }
}
/* .End max-767 */

@media (max-width: 1199px) {
  .third h3 {
    height: 84px;
    padding-top: 26px;
  }
  .heat-third h3 {
    padding-top: 38px;
  }
  .the-fumigation-process .upper {
    height: 84px;
  }
  .the-fumigation-process .upper p {
    margin-top: 27px;
  }
}
@media (max-width: 991px) {
  .third h3 {
    height: 64px;
    padding-top: 21px;
    line-height: 18px;
    font-size: 21px;
    padding-top: 17x;
  }
  .heat-third h3 {
    padding-top: 28px;
  }
  .third h3 span {
    font-size: 17px;
  }
  .the-fumigation-process .upper {
    height: 61px;
  }
  .the-fumigation-process .upper p {
    margin-top: 14px;
    width: 40px;
    height: 40px;
    line-height: 30px;
  }
  .the-fumigation-process .lower h2 {
    font-size: 14px;
    line-height: 16px;
    height: 116px;
  }
  .the-fumigation-process .lower p {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 14px;
  }
}
@media (max-width: 767px) {
  header.master {
    transition: opacity 500ms;
    -webkit-transition: opacity 500ms;
  }
  header.master.nav-up {
    opacity: 0;
  }
  header.master.nav-down {
    opacity: 1;
  }
  div#section0 .container {
    margin-top: 37px;
  }
  p.subhead.mobile {
    font-size: 20px;
    margin-top: 2px;
    letter-spacing: 3px;
    padding-bottom: 0px;
    line-height: 18px;
    padding-bottom: 15px;
  }
  .hero-content-btm img {
    padding-top: 10px;
  }
  .leftOne {
    border-right: 4px solid #fff;
  }
  figure.cd-image-container {
    z-index: 2;
  }
  .reveal-slider .left:after,
  div#section1:after {
    content: '';
    width: 100%;
    height: 260px;
    position: absolute;
    z-index: -1;
    bottom: -12px;
    left: 0px;
  }
  div#section1:after {
    z-index: 1;
  }
  .reveal-slider .right-inner:after,
  img.hover-compare {
    display: none !important;
  }
  .reveal-slider h1:after {
    content: '';
    width: 236px;
    height: 5px;
    position: absolute;
    background-color: #4e8aa2;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    display: block;
  }
  .reveal-slider .left {
    padding-left: 0px;
  }
  .spin-container {
    padding: 10px !important;
    transform: rotateY(0deg) !important;
  }
  .third h3 {
    background: none;
    font-size: 33px;
    line-height: 28px;
    margin-top: 0px;
    margin-bottom: 20px;
    height: auto;
    background-size: inherit !important;
  }
  .third h3 span {
    font-size: 20px;
  }
  .fumigation-third.third h3,
  .third.before-fumigation-third h3 {
    background: url('../svg/mob-hd-purp.svg') no-repeat center;
  }
  .spot-treatment-third.third h3,
  .third.day-of-fumigation-third h3 {
    background: url('../svg/mob-hd-teal.svg') no-repeat center;
  }
  .third.after-fumigation-third h3 {
    background: url('../svg/mob-hd-blue.svg') no-repeat center;
  }
  .heat-third.third h3 {
    background: url('../svg/mob-hd-blue.svg') no-repeat center;
    line-height: 45px;
  }
  .the-fumigation-process .upper {
    background: none;
    height: auto;
  }
  .the-fumigation-process .upper p {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 50px;
    width: 65px;
    height: 65px;
    line-height: 56px;
  }
  #section5 .spin-container {
    padding: 0px !important;
    padding-bottom: 10px !important;
  }
  .the-fumigation-process .lower h2 {
    font-size: 14px;
    line-height: 16px;
  }
  .family-ready .third ul {
    height: auto;
  }
  p.disclaimer {
    padding: 0px 20px;
  }
  button.learn-more.pdf-download.desktop-hide {
    display: none;
  }
  button.learn-more.pdf-download {
    margin-bottom: 20px;
  }
  .more-info-inner h1 {
    font-size: 30px;
    border-bottom: 5px solid #518ba2;
    text-align: center;
    display: inline-block;
    margin: 0 auto;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .more-info-inner p {
    font-size: 14px;
    padding: 0px 10px;
    line-height: 18px;
  }
  .master-footer {
    position: static;
    padding-top: 20px;
    text-align: center;
    padding-bottom: 40px;
  }
  .master-footer img {
    float: none;
    clear: both;
    padding-top: 20px;
  }
  .master-footer span {
    position: static;
    clear: both;
    display: block;
    text-align: center;
    padding-top: 20px;
  }
  div#section10 .fp-tableCell .termites-panel.active-termites {
    bottom: 0px;
    background-position: bottom;
  }
  .how-it-works-mobile-blocks.mobile {
    max-width: 100%;
  }
  .col-sm-7.video {
    background: black;
  }
  span.circle-mid {
    height: 26px;
    width: 26px;
    top: -5px !important;
    margin: 0px !important;
    line-height: 30px;
    text-indent: -1px;
    font-size: 16px;
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .brand.active {
    width: 60px;
    top: -8px;
  }
  .leftOne {
    position: relative;
    top: 5px;
  }
  .leftOne img,
  .rightOne img {
    position: relative;
    top: 9px;
  }
  .rightOne,
  .leftOne {
    margin-bottom: 10px;
  }
  .reveal-slider .right {
    z-index: 5;
    padding-left: 20px;
  }
  .reveal-slider .left {
    z-index: 6;
  }
  .reveal-slider p {
    padding: 12px 13px;
  }
  .reveal-slider .right-inner {
    transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
  div#section2 {
    z-index: 999999;
    margin-top: -49px;
  }
  .reveal-slider p {
    color: #8d8d8d;
  }
  .fumigation-vs-spot-treatment h2,
  .family-ready h2 {
    font-size: 10px !important;
    line-height: 12px !important;
    text-transform: none !important;
    letter-spacing: 0.5px;
  }
  h1 {
    margin-top: -20px !important;
  }
  .bottom-border h1:after {
    margin-top: -2px;
  }
  .video-comment {
    display: none;
  }
  .mobile h2 {
    background-color: #e9eaeb;
    font-size: 10px !important;
    line-height: 12px !important;
    text-transform: none !important;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
    padding: 20px;
    color: #8f8f8f;
    padding-top: 0px;
  }
  #section3 h1,
  #section6 h1 {
    margin-bottom: 10px !important;
    padding-bottom: 0px !important;
  }
  div#section7,
  div#section4 {
    border-top: 5px solid #fff;
  }
  span.section-title {
    padding-top: 10px;
  }
  span.carousel-next,
  span.carousel-prev {
    text-indent: -9999px;
    background: url('../svg/white-carrot-fwd.svg') no-repeat !important;
    background-size: 50% !important;
    background-position: 50% !important;
  }
  span.carousel-prev {
    text-indent: -9999px;
    background: url('../svg/white-carrot.svg') no-repeat !important;
    background-size: 50% !important;
    background-position: 50% !important;
  }
  .master-footer span {
    padding: 30px 60px;
  }
  .master-footer img {
    padding-left: 0px;
  }
  .termites-panel.active-termites {
    display: none;
  }
  img.mobile.termite-mob {
    margin: 0 auto;
    width: 60px;
    padding-top: 30px;
    height: auto;
  }
  .third .vertical-align-outer {
    display: block;
  }
  .desktop-hide {
    display: none;
  }
  .midP,
  .bottomP,
  .midP .fp-tableCell,
  .bottomP .fp-tableCell {
    height: auto !important;
  }
  .the-fumigation-process .lower h2 {
    height: auto;
  }
  .reveal-slider-container:after {
    content: '';
    position: absolute;
    width: 200%;
    height: 360px;
    background-color: rgb(233, 234, 235);
    display: block;
    bottom: 0px;
    left: 0px;
  }
  .reveal-slider p {
    max-width: 75%;
    margin: 0 auto;
  }
  .left-inner-reveal {
    vertical-align: bottom;
  }
  .reveal-slider .left {
    position: relative;
    top: -30px;
  }
  #section0 {
    border-bottom: 5px solid #fff;
  }
  .fumigation-vs-spot-treatment h2,
  .family-ready h2 {
    max-width: 90% !important;
  }
  .col-sm-7.video {
    background: #fff;
  }
  #section6 h1 {
    white-space: nowrap;
    transform: scale(0.9);
    position: relative;
    left: -26px;
  }
  @media (min-width: 359px) {
    #section6 h1 {
      white-space: nowrap;
      transform: scale(0.9);
      position: relative;
      left: -10px;
    }
  }
  body span.carousel-next,
  body span.carousel-prev {
    opacity: 0.95 !important;
  }
  .family-ready .third .vertical-align-outer {
    height: auto;
  }
  .third h3 {
    width: 222px;
    margin: 0 auto;
    height: 96px;
  }
  .more-info-inner p {
    font-size: 9px;
    padding: 0px 10px;
    line-height: 12px;
    max-width: 100%;
    top: -10px;
    position: relative;
  }
  .more-info-inner p.main-disclaimer {
    color: #8d8d8d;
    font-size: 6px;
    line-height: 9px;
    padding-top: 10px;
    padding-bottom: 40px;
    max-width: 70%;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .more-info-inner {
    padding-top: 30px;
  }
  span.extra {
    height: 50px;
    width: 50px;
    margin-left: 59px;
    margin-top: -49px;
  }
  .more-info-inner img {
    width: 18px;
    margin-top: 6px !important;
    margin-left: 12px !important;
  }
  .master-footer {
    margin-top: 20px;
    padding: 0px;
    background-color: #fff;
  }
  .master-footer img {
    max-width: 40%;
  }
  .master-footer span {
    padding: 0px;
    font-size: 6px;
    padding-top: 10px;
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  div#section7 {
    height: auto !important;
  }
  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */

    padding-top: 25px;
    height: 0;
  }
  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  #section2 h1 {
    white-space: nowrap;
  }
  p.subhead.mobile {
    margin-top: -20px;
  }
  .leftOne,
  .rightOne {
    border-right: none;
    width: 45%;
    padding-right: 0px;
  }
  .rightOne {
    position: relative;
    top: 5px;
    right: -31px;
    width: 47%;
  }
  .leftOne {
    position: relative;
    left: -10px;
  }
  .leftOne:after {
    content: '';
    width: 4px;
    height: 89%;
    background-color: #fff;
    position: absolute;
    top: 21px;
    left: 100%;
    margin-left: 22px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
  }
  img.carousel-object {
    width: 90%;
  }
  span.carousel-next {
    left: 14px;
    position: relative;
  }
  span.carousel-prev {
    position: relative;
    left: -14px;
  }
  #section10 .container:before {
    content: '';
    width: 100%;
    height: 5px;
    background-color: #fff;
    position: relative;
    top: 17px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
  }
  #section10 .container:after {
    content: '';
    width: 100%;
    height: 5px;
    background-color: #fff;
    position: relative;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    top: 24px;
  }
  button.learn-more {
    white-space: nowrap;
  }
  .master-footer {
    background-color: transparent;
  }
}

@media (max-width: 767px) {
  html,
  body {
    -webkit-text-size-adjust: none;
    overflow-x: hidden !important;
  }

  .menu ul li a:hover {
    color: #4e89a0;
  }

  .menu ul li a:hover span {
    border-color: #518ba2;
  }

  #too-big {
    font-size: 25px !important;
  }

  body .carousel-control *,
  body .carousel-control {
    opacity: 0.9 !important;
  }

  .the-fumigation-process .lower h2:after {
    bottom: inherit;
    width: 100%;
    margin-top: 13px;
  }

  .the-fumigation-process .lower h2 {
    position: relative;
  }

  img.subhead-mob.mobile-svg {
    width: 100%;
    margin: 0 auto;
    display: block;
  }

  .menu-toggle .menu-icon {
    margin-top: -15px;
  }

  header.master {
    top: 0px;
    position: fixed;
    width: 100%;
    -webkit-transition: all 400ms ease-out 400ms;
    -moz-transition: all 400ms ease-out 400ms;
    -o-transition: all 400ms ease-out 400ms;
    transition: all 400ms ease-out 400ms;
  }

  header.master.out {
    top: -86px;
  }

  .brand,
  .menu-toggle {
    position: absolute;
  }

  #section1 img.subhead-mob.mobile-svg {
    width: 82%;
  }

  .reveal-slider {
    top: 36px;
  }

  .reveal-slider .left {
    left: 0px;
    padding-bottom: 20px !important;
  }

  .right-inner,
  .reveal-slider .right-inner {
    left: 0px;
    margin-left: 0px;
    padding-left: 0px;
  }

  .reveal-slider p {
    max-width: 100%;
  }

  .carousel-control.right {
    position: absolute;
  }

  .mobile,
  button.mobile.learn-more.pdf-download {
    display: block;
  }
}

@media (min-width: 767px) and (max-width: 790px) {
  .col-sm-7.video {
    left: 0px;
  }

  .video-comment {
    left: 5px;
  }
}

@media (max-width: 321px) {
  #section6 h1 {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    left: -36px;
  }
  #section2 h1 {
    white-space: nowrap;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    position: relative !important;
    left: -5px !important;
  }
  div.right-inner {
    left: -20px !important;
  }
  img.subhead-mob.mobile-svg {
    margin-top: 4px;
  }
}

@media (max-width: 855px) {
  .wf-out-left {
    transform: translate(-12px, 25px);
    -webkit-transform: translate(-12px, 25px);
    -ms-transform: translate(-12px, 25px);
    /*x: 5;*/
  }

  .wf-out-right {
    transform: translate(82.2px, 25px);
    -webkit-transform: translate(82.2px, 25px);
    -ms-transform: translate(82.2px, 25px);
    /*x: 5;*/
  }

  .hiw-left {
    transform: translate(-9px, 25px);
    -webkit-transform: translate(-9px, 25px);
    -ms-transform: translate(-9px, 25px);
    /*x: 3;*/
  }

  .hiw-right {
    transform: translate(83px, 25px);
    -webkit-transform: translate(83px, 25px);
    -ms-transform: translate(83px, 25px);
    /*x: -3;*/
  }
}

/*orientation message test*/
@media (max-width: 768px) and (orientation: landscape) {
  .orientationMessage {
    display: block;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 15% 25%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5000;
  }
  .orientationMessage > div {
    color: #fffff;
    text-align: center;
    font-weight: normal;
  }
}
