/* ----------------------------------------------------------------
Simple Reset
---------------------------------------------------------------- */
* {
  font-display: swap;
}
html,
body,
p,
div {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  color: #fff;
}
button,
button:focus,
button:active {
  outline: none;
}

a:focus,
a:link,
a,
a:hover,
a:active {
  text-decoration: none !important;
}
/* ----------------------------------------------------------------
Overrides
---------------------------------------------------------------- */

body .no-padding {
  padding: 0px !important;
}
body .bottom-5 {
  padding-bottom: 5px !important;
}
.block {
  display: block;
}
.hidden {
  display: none;
}
.master,
.brand,
.menu-toggle {
  z-index: 99999 !important;
}
.bottom-termites {
  display: none;
}
.menu {
  z-index: 9999 !important;
}
.ip-header {
  z-index: 999999 !important;
}
.hiw-1,
.hiw-2,
.hiw-3,
.hiw-4 {
  display: none;
  transform-origin: center;
}
.brand.active img {
  position: relative;
  top: -10px;
}

.ie8 {
  display: none;
}

/* ----------------------------------------------------------------
Preloader
---------------------------------------------------------------- */
/*Currently loaded externally*/
/* ----------------------------------------------------------------
Global / Core
---------------------------------------------------------------- */

body {
  background: #444;
}
.house_container {
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
}
.house {
  width: 100%;
  height: 100%;
}
.fullpage {
  z-index: 100;
}
.fullpage.clickable {
  z-index: 0;
}
/*Panel Blocks*/

.panel-color-blocks {
  z-index: -1;
  position: relative;
}
.panel-color-blocks .bg-panel.first {
  bottom: 0px;
}
.panel-color-blocks .bg-panel {
  width: 100%;
  height: 100%;
  bottom: -100%;
  left: 0px;
  z-index: 3;
  display: block;
  position: fixed;
  -webkit-transition: all 400ms ease-out 400ms;
  -moz-transition: all 400ms ease-out 400ms;
  -o-transition: all 400ms ease-out 400ms;
  transition: all 400ms ease-out 400ms;
}
.panel-color-blocks .bg-panel.active {
  bottom: 0px;
}
.panel-color-blocks .bg-panel.top {
  bottom: 100%;
}
.yellow-panel {
  background: rgba(227, 197, 79, 0.2) !important;
}
.blue-panel {
  background: rgba(16, 79, 165, 0.3) !important;
}
.purple-panel {
  background: rgba(132, 63, 134, 0.4) !important;
}
.orange-panel {
  background: rgba(239, 164, 55, 0.3) !important;
}

.panel-color-blocks .bg-panel.active.top {
  bottom: 0px;
}

.gradient-overlay {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+54,000000+100&amp;0+55,0.2+100 */

  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 54%, rgba(0, 0, 0, 0) 55%, rgba(0, 0, 0, 0.4) 100%);
  /* FF3.6+ */

  background: -webkit-gradient(linear, left top, left bottom, color-stop(54%, rgba(0, 0, 0, 0)), color-stop(55%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.4)));
  /* Chrome,Safari4+ */

  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 54%, rgba(0, 0, 0, 0) 55%, rgba(0, 0, 0, 0.4) 100%);
  /* Chrome10+,Safari5.1+ */

  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 54%, rgba(0, 0, 0, 0) 55%, rgba(0, 0, 0, 0.4) 100%);
  /* Opera 11.10+ */

  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 54%, rgba(0, 0, 0, 0) 55%, rgba(0, 0, 0, 0.4) 100%);
  /* IE10+ */

  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 54%, rgba(0, 0, 0, 0) 55%, rgba(0, 0, 0, 0.4) 100%);
  /* W3C */
}
.gradient-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}
/*Down Arrow*/

.down-arrow-container {
  position: fixed;
  left: 50%;
  bottom: 12px;
  width: 60px;
  height: 35px;
  margin-left: -30px;
  /* -webkit-transition: all 500ms ease 0s;
  -moz-transition: all 500ms ease 0s;
  -o-transition: all 500ms ease 0s; */
  transition: all 500ms ease 0s;
  opacity: 0.5;

  z-index: 500;

  cursor: pointer;
}
.bounce {
  position: fixed;
  /* animation-delay: 0.5s; */
  animation: bounce 1s 4;
}
@keyframes bounce {
  0% {
    bottom: 35px;
  }
  25%,
  75% {
    bottom: 45px;
  }
  50% {
    bottom: 50px;
  }
  100% {
    bottom: 30px;
  }
}
/* .down-arrow-container.inactive {
	bottom: -200px;
} */
/*Parallax*/

#parallax {
  margin: 0;
  /*transition: margin .1s ease-in;*/

  background: url('../img/bg.jpg');
  background-size: cover;
  z-index: -1;
  position: fixed;
  top: 0px;
  left: 0px;
}
svg:not(:root) {
  overflow: visible !important;
}
/* ----------------------------------------------------------------
Typography
---------------------------------------------------------------- */

body,
p,
h2,
h3,
h4 {
  font-family: arial, sans-serif;
  font-weight: bold;
}
h1 {
  font-size: 62px;
  line-height: 62px;
  margin-bottom: 62px;
  font-family: Erazm-Regular;
  font-weight: normal;
  font-style: normal;
  color: #518ba2;
  text-transform: uppercase;
  margin-top: 0px;
}
span.section-title,
h1 {
  text-align: center;
  display: block;
}
h2 {
  font-size: 23px;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 28px;
  margin-top: 0px;
}
.section-title {
  text-transform: uppercase;
  color: #518ba2;
  letter-spacing: 2px;
  font-size: 14px;
}
span.circle-mid {
  display: inline-block;
  background: #518ba2;
  color: #fff;
  font-size: 20px;
  width: 40px;
  height: 40px;
  line-height: 42px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  position: relative;
  top: -14px;
}
.col-lg-12 h1 {
  display: inline-block;
  border-bottom: 5px solid #4e89a0;
}
.the-fumigation-process .lower h2,
.the-fumigation-process .lower p,
.video-comment p {
  text-align: left;
}
text.object.lwr.text-obj .firstobj {
  letter-spacing: 0.2px;
}
/* ----------------------------------------------------------------
Brand Header & Navigation
---------------------------------------------------------------- */

.ip-header {
  z-index: 101;
  position: relative;
}
.brand {
  position: fixed;
  cursor: pointer;
  top: -140px;
  left: 40px;
  height: 120px;
  width: 80px;
  -webkit-transition: all 500ms ease 0s;
  -moz-transition: all 500ms ease 0s;
  -o-transition: all 500ms ease 0s;
  transition: all 500ms ease 0s;
}
.brand a {
  display: block;
}
.brand.active {
  top: -11px;
}
.menu {
  position: fixed;
  z-index: 8;
  top: 0px;
  height: 100%;
  width: 394px;
  overflow: hidden;
  right: -394px;
  padding: 0;
  -webkit-transition: all 500ms ease 0s;
  -moz-transition: all 500ms ease 0s;
  -o-transition: all 500ms ease 0s;
  transition: all 500ms ease 0s;
  display: table;
  background: #fff;
  background: rgba(255, 255, 255, 0.98);
}
.menu ul {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.menu ul li {
  margin: 0px;
  padding: 0px 0px 30px 0px;
}

.menu ul li:last-child {
  padding-bottom: 0px;
}
.menu ul li:first-child a {
  padding-top: 0px;
}
.menu ul li:last-child a {
  padding-bottom: 0px;
}
.menu ul li a {
  color: #4e89a0;
  display: block;
  font-size: 24px;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 0px 10px 0px;
  letter-spacing: 2px;
  font-family: arial, sans-serif;
}
.menu ul li a:hover {
  color: #9d4e9e;
}
.menu ul li a:hover span {
  border-color: #9d4e9e;
}
.menu ul li a span {
  width: 42px;
  height: 42px;
  display: block;
  background: #fff;
  line-height: 13px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 5px solid #518ba2;
  padding: 5px;
  margin: 0 auto;
  margin-bottom: 5px;
}
.menu.active {
  right: 0px;
}
.menu-toggle {
  position: fixed;
  display: table;
  top: 34px;
  right: 50px;
  z-index: 10;
}
.menu-toggle p {
  display: inline;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 2px;
  display: table-cell;
  vertical-align: middle;
  padding-right: 5px;
  line-height: 22px;
  opacity: 0;
  -webkit-transition: all 500ms ease 0s;
  -moz-transition: all 500ms ease 0s;
  -o-transition: all 500ms ease 0s;
  transition: all 500ms ease 0s;
}
.menu-toggle p.active {
  opacity: 1;
}
.menu-toggle:hover .menu-icon {
  border: 5px solid #9d4e9e;
}
.menu-toggle:hover .menu-icon span {
  background-color: #9d4e9e;
}
.menu-toggle:hover .menu-icon span:after,
.menu-toggle:hover .menu-icon span:before {
  background-color: #9d4e9e;
}
.menu-toggle .menu-icon {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 42px;
  height: 42px;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
  cursor: pointer;
  -webkit-transition: background 0.3s 0.3s;
  transition: background 0.3s 0.3s;
  background-color: #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 5px solid #518ba2;
  margin-top: -2px;
}
.menu-toggle .menu-icon:focus {
  outline: none;
}
.menu-toggle .menu-icon span {
  display: block;
  position: absolute;
  top: 15px;
  left: 7px;
  right: 7px;
  height: 3px;
  background-color: #518ba2;
  -webkit-transition: background 0s;
  transition: background 0s;
}
.menu-toggle .menu-icon span:before,
.menu-toggle .menu-icon span:after {
  -webkit-transition: background-color 0s 0.3s;
  transition: background-color 0s 0.3s;
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #518ba2;
  content: '';
  -webkit-transition-duration: 0.3s, 0.3s;
  transition-duration: 0.3s, 0.3s;
  -webkit-transition-delay: 0.3s, 0.3s;
  transition-delay: 0.3s, 0.3s;
}
.menu-toggle .menu-icon span:before {
  top: -6px;
  -webkit-transition-property: top, -webkit-transform;
  transition-property: top, transform;
}
.menu-toggle .menu-icon span:after {
  bottom: -6px;
  -webkit-transition-property: bottom, -webkit-transform;
  transition-property: bottom, transform;
}
.menu-toggle .menu-icon.active span {
  -webkit-transition: background 0s 0.3s;
  transition: background 0s 0.3s;
  background: none;
}
.menu-toggle .menu-icon.active span:before {
  top: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.menu-toggle .menu-icon.active span:after {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.menu-toggle .menu-icon.active span:before,
.menu-toggle .menu-icon.active span:after {
  -webkit-transition-delay: 0s, 0.3s;
  transition-delay: 0s, 0.3s;
}
.menu li img.standard {
  display: block;
}
.menu li img.hover {
  display: none;
}
.menu li img.standard.hovered {
  display: none;
}
.menu li img.hover.hovered {
  display: block;
  position: relative;
  top: -3.4px;
}

.menu li.Why_Fumigate img.hover.hovered {
  display: block;
  position: relative;
  top: -4px;
}

.menu li.How_It_Works img.hover.hovered {
  display: block;
  position: relative;
  top: -4px;
}

.menu ul li.How_To_Prepare a span {
  padding: 4px;
}
.menu-toggle {
  font-family: arial, sans-serif;
  cursor: pointer;
}
/* ----------------------------------------------------------------
SVG Specific
---------------------------------------------------------------- */

.the-svg {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.object {
  display: none;
}
text.object.lwr.text-obj {
  font-size: 2.7px;
  font-family: Erazm-Regular;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0.3px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35);
  -webkit-text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35);
}
text.text-obj.button-object {
  font-family: Erazm-Regular;
  font-weight: normal;
  font-style: normal;
  font-size: 2.7px;
  text-transform: uppercase;
  letter-spacing: 0.1px;
  cursor: pointer;
}
.button * {
  cursor: pointer;
}
.button:hover rect {
  stroke: #7954a3 !important;
}
.button:hover text {
  fill: #7954a3;
}
.house-window-object {
  opacity: 0;
}
.the-x {
  opacity: 0;
}
.the-art {
  opacity: 1;
}
.icon circle {
  cursor: pointer;
}
.wf-left,
.wf-right {
  display: none;
  transform-origin: center;
}
/*image.bubble.pets-txt.object {
    transform: scale(0.85);
    -webkit-transform: scale(0.85);
    -ms-transform: scale(0.85);
    -moz-transform: scale(0.85);
    y: 34;
    x: 33.5;
}
image.bubble.apple-txt.object {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -moz-transform: scale(1.1);
    x: 23.2;
    y: 24.5;
}
image.bubble.mail-txt.object {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -moz-transform: scale(0.9);
    x: 30;
    y: 30.5;
}*/
/* ----------------------------------------------------------------
Widgets
---------------------------------------------------------------- */
/* --------------------------------
    Reveal Slider
    -------------------------------- */

.reveal-slider .left {
  width: 64%;
  float: left;
  padding-left: 0px;
  position: relative;
  z-index: 2;
}
.reveal-slider .right {
  width: 25%;
  position: relative;
  z-index: 1;
  left: 5%;
}
.reveal-slider {
  z-index: 99999;
  position: fixed;
  background: #f8f8f4;
  border: 5px solid #fff;
  left: 50%;
  margin-left: -542px;
  top: 50%;
  height: 820px;
  margin-top: -410px;
  overflow: hidden;
  display: none;
}
.reveal-slider h1 {
  margin-bottom: 10px;
}
span.close-reveal-slider {
  position: absolute;
  top: 0px;
  right: 20px;
  color: #518ba2;
  font-size: 40px;
  font-weight: 100;
  cursor: pointer;
  background: url('../img/close-x.png') no-repeat;
  height: 24px;
  width: 25px;
  background-position: 0px 0px;
  margin-top: 10px;
  right: 10px;
  z-index: 3;
  background-size: 100%;
}
span.close-reveal-slider:hover {
  color: #815099;
  background-position: 0px -27px;
}
.reveal-slider p {
  text-align: center;
  font-family: arial, sans-serif;
  color: #4e8aa2;
  line-height: 24px;
  border-bottom: 5px solid #4e8aa2;
  padding-bottom: 20px;
  font-size: 15px;
}
span.cd-handle.draggable {
  margin-left: -25px;
}
span.cd-handle:after {
  content: '';
  position: absolute;
  background-color: #4e89a0;
  height: 447px;
  width: 5px;
  margin-top: -236px;
  margin-left: 15px;
  z-index: -1;
  border-radius: 6px;
  -webkit-border-radius: 6px;
}
.cd-handle span.in-handle {
  display: block;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  /*background: #dc717d url("../img/cd-arrows.svg") no-repeat center center;*/

  background: #4e89a0;
  border: 4px solid #fff;
  cursor: move;
  margin-top: -5px;
  margin-left: -5px;
}
.cd-handle span.in-handle {
  color: #fff;
  line-height: 28px;
  text-align: center;
  font-size: 40px;
  text-indent: 2px;
}
.cd-handle span.in-handle:hover,
.cd-handle:active span.in-handle,
.cd-handle:focus span.in-handle {
  background: #82509b;
}
.reveal-slider .left {
  float: left;
  padding-left: 40px;
  padding-top: 148px;
}
.right {
  float: left;
  display: table;
  height: 100%;
}
.right-inner {
  display: table-cell;
  vertical-align: middle;
}
.cd-image-container {
  position: relative;
  width: 84%;
  margin: 0em auto;
}
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.cd-image-container {
  position: relative;
  width: 100%;
  margin: 0em auto;
}
.cd-image-container img {
  display: block;
}
.cd-image-label {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #ffffff;
  padding: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -ms-transform: translateY(20px);
  -o-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: -webkit-transform 0.3s 0.7s, opacity 0.3s 0.7s;
  -moz-transition: -moz-transform 0.3s 0.7s, opacity 0.3s 0.7s;
  transition: transform 0.3s 0.7s, opacity 0.3s 0.7s;
}
.cd-image-label.is-hidden {
  visibility: hidden;
}
.is-visible .cd-image-label {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
.cd-resize-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  overflow: hidden;
  /* Force Hardware Acceleration in WebKit */

  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.cd-resize-img img {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  width: auto;
  max-width: none;
}
.cd-resize-img .cd-image-label {
  right: auto;
  left: 0;
}
.is-visible .cd-resize-img {
  width: 74%;
}
.cd-handle {
  position: absolute;
  height: 44px;
  width: 44px;
  /* center the element */

  left: 74%;
  top: 50%;
  margin-left: -22px;
  margin-top: -22px;
  border-radius: 50%;
  /*background: #dc717d url("../img/cd-arrows.svg") no-repeat center center;*/

  background: #4e89a0;
  border: 4px solid #fff;
  cursor: move;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0) scale(0);
  -moz-transform: translate3d(0, 0, 0) scale(0);
  -ms-transform: translate3d(0, 0, 0) scale(0);
  -o-transform: translate3d(0, 0, 0) scale(0);
  transform: translate3d(0, 0, 0) scale(0);
}
.cd-handle.draggable {
  /* change background color when element is active */

  background-color: #445b7c;
}
.is-visible .cd-handle {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  -moz-transform: translate3d(0, 0, 0) scale(1);
  -ms-transform: translate3d(0, 0, 0) scale(1);
  -o-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
  -webkit-transition: -webkit-transform 0.3s 0.7s, opacity 0s 0.7s;
  -moz-transition: -moz-transform 0.3s 0.7s, opacity 0s 0.7s;
  transition: transform 0.3s 0.7s, opacity 0s 0.7s;
}
/* ----------------------------------------------------------------
Sections
---------------------------------------------------------------- */
/* ----------------------------------------------------------------
Why Fumigate - A / How to Prepare - A
---------------------------------------------------------------- */

.fumigation-vs-spot-treatment h1,
.family-ready h1 {
  border-bottom: 5px solid #4e89a0;
  margin-bottom: 5px;
  display: inline-block;
}
.fumigation-vs-spot-treatment .col-lg-12,
.family-ready .col-lg-12,
.col-lg-12 {
  text-align: center;
}
.fumigation-vs-spot-treatment h2,
.family-ready h2 {
  color: #8d8d8d;
  font-family: arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  max-width: 800px !important;
  margin: 0 auto;
  padding-bottom: 0px;
  letter-spacing: 1px;
  line-height: 19px;
  padding-top: 9px;
}
.family-ready h2 {
  max-width: none;
  padding-bottom: 0px;
}
.third {
  text-align: left;
}
.fumigation-vs-spot-treatment .third ul {
  list-style: none;
  padding: 0px;
}
.third li {
  display: block;
  padding: 8px;
  font-size: 14px;
  margin-bottom: 10px;
  border: 5px solid #fff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  margin-bottom: 4px;
}
.no-touch .third li.compared {
  background: #82509b !important;
}
.third.spot-treatment-third.col-sm-4 p {
  font-size: 12px;
  color: #4e89a0;
  text-align: center;
}
.third h3 {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 29px;
  color: #fff;
  font-family: Erazm-Regular;
  height: 101px;
  margin-bottom: 0px;
  font-weight: 100;
  line-height: 26px;
  padding-top: 33px;
  background-size: cover !important;
  margin-bottom: 4px;
}
.third h3 span {
  font-size: 24px;
  display: block;
  clear: both;
}
.fumigation-third li {
  background: #9d4e9e;
}
.fumigation-third h3 {
  background: url('../svg/why-fumigate/heading-a.svg') no-repeat center;
  background-size: cover;
}
.spot-treatment-third li {
  background: #4e89a0;
}
.spot-treatment-third h3 {
  background: url('../svg/why-fumigate/heading-b.svg') no-repeat center;
  background-size: cover;
}
.heat-third li {
  background: #78b2e0;
}
.heat-third h3 {
  background: url('../svg/why-fumigate/heading-c.svg') no-repeat center;
  padding-top: 49px;
  background-size: cover;
}
.third.col-sm-4 {
  padding: 0.25%;
}
.family-ready .third .vertical-align-outer {
  padding: 10px;
  border: 5px solid #fff;
  height: 400px;
}
.before-fumigation-third .vertical-align-outer {
  background: #9d4e9e;
}
.before-fumigation-third h3 {
  background: url('../svg/why-fumigate/heading-a.svg') no-repeat center;
  background-size: cover;
}
.day-of-fumigation-third h3 {
  background: url('../svg/why-fumigate/heading-b.svg') no-repeat center;
  background-size: cover;
}
.after-fumigation-third h3 {
  background: url('../svg/why-fumigate/heading-c.svg') no-repeat center;
  background-size: cover;
}
.day-of-fumigation-third .vertical-align-outer {
  background: #4e89a0;
}
.after-fumigation-third .vertical-align-outer {
  background: #78b2e0;
}
.family-ready li {
  font-size: 15px;
  font-weight: normal;
  padding: 0px 0px 3px 0px;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
}
.third .vertical-align-outer {
  padding: 20px 10px 20px 20px;
  display: table;
}
.third .vertical-align-inner {
  display: table-cell;
  vertical-align: middle;
}
img.hover-compare {
  width: 0px;
  height: auto;
  position: absolute;
  right: 87px;
  z-index: 9;
  margin-top: 79px;
  transition: all 500ms ease 1500ms;
  -webkit-transition: all 500ms ease 1500ms;
  -moz-transition: all 500ms ease 1500ms;
}
img.hover-compare.activeCompare {
  width: 133px;
  margin-top: -44px;
  right: -36px;
}
.family-ready li,
.family-ready ul {
  padding-left: 5px;
  padding-right: 0px;
}
button.learn-more.pdf-download {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
}
/* ----------------------------------------------------------------
How it Works - A
---------------------------------------------------------------- */

.the-fumigation-process .upper {
  background: url('../svg/how-it-works/title-top.svg') no-repeat center;
  height: 103px;
  background-size: cover;
}
.the-fumigation-process .upper p {
  display: inline-block;
  background: #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  height: 45px;
  width: 45px;
  line-height: 36px;
  color: #518ba2;
  border: 5px solid #9d4e9e;
  text-align: center;
  font-size: 26px;
  margin-top: 38px;
}
.the-fumigation-process .lower {
  background: #9d4e9e;
  border: 5px solid #fff;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
  color: #fff;
  margin-top: 5px;
  padding: 25px;
  height: 330px;
}

.the-fumigation-process .lower h2 {
  padding-bottom: 12px;
  border-bottom: 3px solid #fff;
  height: 189px;
  letter-spacing: 2px;
}
.the-fumigation-process .lower p {
  font-size: 14px;
  letter-spacing: 1px;
}

@media (min-width: 989px) and (max-width: 1200px) {
  .the-fumigation-process .lower h2 {
    font-size: 20px;
    height: 150px;
  }

  .the-fumigation-process .lower {
    height: 380px;
  }
}

body .spin-container {
  position: relative;
  perspective: 1000;
  transform-style: preserve-3d;
  transition: all 300ms linear 1s;
  transform: rotateY(90deg);
  display: block;
}
body .spin-container.active {
  transform: rotateY(0deg);
  display: block;
}
/* ----------------------------------------------------------------
How it Works -B
---------------------------------------------------------------- */

.col-sm-7.video {
  overflow-y: hidden;
  overflow: hidden;
  padding: 0px;
}
.video-comment {
  position: relative;
  left: 10px;
}
.col-sm-7.video {
  position: relative;
  left: -15px;
}
object {
  border: 5px solid #fff;
}
/*.video:after {
  content: '';
  position: absolute;
  width: 150px;
  height: 150px;
  z-index: 5;
  margin-top: -288px;
  margin-left: 252px;
  background: url('../img/play.png') no-repeat;
  opacity: 0.9;
  -webkit-transition: all 500ms ease 0s;
  -moz-transition: all 500ms ease 0s;
  -o-transition: all 500ms ease 0s;
  transition: all 500ms ease 0s;
}*/

.video:hover:after {
  opacity: 1;
}
.video-comment {
  border: 5px solid #fff;
  display: table;
  height: 426px;
  background: #518ba2;
}
.video-comment p {
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  padding: 15px;
  line-height: 25px;
  letter-spacing: 2px;
}
/* ----------------------------------------------------------------
Final Panel
---------------------------------------------------------------- */

.more-info-inner img {
  position: absolute;
  bottom: -15%;
  width: 70%;
  left: 20%;
}

.more-info-inner p {
  font-size: 20px;
  text-align: center;
  color: #9d4f9e;
  font-weight: normal;
  text-transform: uppercase;
  max-width: 600px;
  margin: 0 auto;
  line-height: 29px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  max-width: 500px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.more-info-inner p.main-disclaimer {
  max-width: none;
}
button.learn-more {
  margin: 0 auto;
  font-family: Erazm-Regular;
  display: block;
  border: 5px solid #9d4e9e;
  border-radius: 30px;
  width: 210px;
  margin-top: 10px;
  background-color: #fff;
  color: #9d4e9e;
  text-transform: uppercase;
  font-size: 35px;
  border-color: #4e89a0;
  color: #4e89a0;
  font-weight: 100;
  line-height: 37px;
  position: relative;
  padding-top: 9px;
  letter-spacing: 1px;
}
p.disclaimer {
  font-size: 10px;
  clear: both;
  font-weight: 100;
  color: #4e89a0;
  padding-top: 14px;
  padding-bottom: 13px;
  line-height: 12px;
  max-width: 800px;
  margin: 0 auto;
}
button.learn-more:hover {
  border-color: #9d4e9e;
  color: #9d4e9e;
}
div#section10 .fp-tableCell .termites-panel {
  background: url('../img/termites-temp.png') no-repeat;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 116%;
  bottom: -50%;
  left: 0px;
  transition: all 1500ms ease;
  -webkit-transition: all 1500ms ease;
  -moz-transition: all 1500ms ease;
  z-index: 0;
  background-size: 100%;
}
div#section10 .fp-tableCell .termites-panel.active-termites {
  bottom: 3%;
}

.learn-more-container {
  cursor: pointer;
}
.learn-more-container:hover button {
  border-color: #9d4e9e;
  color: #9d4e9e;
}
.learn-more-container:hover .extra {
  border-color: #9d4e9e;
  color: #9d4e9e;
}
.purp-hover {
  display: none;
}
.learn-more-container:hover .teal-standard {
  display: none;
}
.learn-more-container:hover .purp-hover {
  display: block;
  opacity: 1;
}
span.extra {
  display: block;
  width: 66px;
  position: absolute;
  height: 66px;
  border: 5px solid #4e89a0;
  border-radius: 50%;
  background-color: #fff;
  left: 50%;
  margin-left: 87px;
  margin-top: -61px;
  text-align: left;
}
.button-contain {
  position: relative;
}
.more-info-inner .extra img {
  position: static;
  margin-top: 10px;
  margin-left: 10px;
}
img.bottom-termites {
  width: 120%;
  left: -10%;
  bottom: -10%;
}
button.learn-more.pdf-download {
  font-size: 20px;
  color: #9d4e9e;
  border: 5px solid #9d4e9e;
  width: auto;
  white-space: nowrap;
  line-height: 17px;
  padding-bottom: 5px;
}
button.learn-more.pdf-download:hover {
  border-color: #82509b;
  color: #82509b;
}
.Why_Fumigate span img {
  position: relative;
  left: 1px;
  top: -4px;
}

li.How_It_Works span img {
  top: -3px;
  position: relative;
}

li.How_To_Prepare span img.hover.hovered {
  top: 0px;
}

.menu li img.hover {
  position: relative;
  top: 2px;
}
.cd-handle span.in-handle {
  background: #4e89a0 url('../svg/white-carrot.svg') no-repeat;
  background-size: 50%;
  background-position: 7px center;
}
.cd-handle.goRight span.in-handle {
  background: #4e89a0 url('../svg/white-carrot-fwd.svg') no-repeat;
  background-size: 50%;
  background-position: 12px center;
}

.cd-handle span.in-handle:hover {
  background: #82509b url('../svg/white-carrot.svg') no-repeat;
  background-size: 50%;
  background-position: 7px center;
}

.cd-handle.goRight span.in-handle:hover {
  background: #82509b url('../svg/white-carrot-fwd.svg') no-repeat;
  background-size: 50%;
  background-position: 12px center;
}

.more-info-inner h1 {
  margin-bottom: 3px;
  letter-spacing: 2px;
}
.more-info-inner p.main-disclaimer {
  color: #8d8d8d;
  font-size: 9px;
  line-height: 12px;
  padding-top: 10px;
  padding-bottom: 40px;
}
span.extra {
  display: block;
  width: 59px;
  position: absolute;
  height: 59px;
  border: 5px solid #4e89a0;
  border-radius: 50%;
  background-color: #fff;
  left: 50%;
  margin-left: 85px;
  margin-top: -59px;
}
.master-footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
}
.master-footer img {
  float: left;
  width: 175px;
  height: auto;
  position: static;
  padding-left: 43px;
}
.master-footer span {
  position: absolute;
  right: 30px;
  color: gray;
  text-align: right;
  font-size: 10px;
  padding-top: 15px;
  font-weight: normal;
}
.more-info-inner h1:after {
  display: none;
}
.family-ready li,
.family-ready ul {
  position: relative;
}
.family-ready li:before {
  content: '\25CF';
  font-size: 10px;
  position: absolute;
  margin-left: -11px;
  margin-top: 3px;
}
.family-ready li {
  padding-left: 10px;
}
.more-info-inner {
  position: relative;
  z-index: 2;
}

#section8 ul {
  padding-left: 0px;
}

.family-ready .third .vertical-align-outer {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
}
/* -------------------------------------------------------------------------------------------------------------------------------
Border Overrides
-------------------------------------------------------------------------------------------------------------------------------  */

.reveal-slider .right-inner:after {
  content: '';
  width: 80%;
  height: 5px;
  position: absolute;
  background-color: #4e8aa2;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  margin-left: 10%;
}
.reveal-slider p {
  border: none;
  display: block;
}
h1 {
  border-bottom: none !important;
}
.bottom-border {
  text-align: center;
}
.more-info-inner h1 {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
}
.bottom-border h1:after {
  content: '';
  width: 100%;
  height: 5px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background-color: #4e8aa2;
  display: block;
  margin-top: -5px;
}
button.learn-more.pdf-download {
  left: 0px;
  padding-left: 15px;
  padding-right: 15px;
}
.more-info-inner img {
  width: 22px;
  margin-top: 8px !important;
  margin-left: 15px !important;
}
.reveal-slider .left {
  padding: 0px !important;
  margin: 0px !important;
}
.cd-image-container img {
  max-width: 100%;
}
.left-inner-reveal {
  display: table-cell;
  vertical-align: middle;
}
.reveal-slider .left {
  display: table;
  height: 100%;
  position: relative;
  left: 5%;
}
.cd-resize-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  overflow: hidden;
  background: url('../svg/reveal/reveal-house.png') no-repeat left top;
  background-size: auto 100%;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.reveal-slider .right-inner {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  left: 8%;
}
.the-fumigation-process .lower h2 {
  border-bottom: none;
}
/* .the-fumigation-process .lower h2:after {
	content: '';
	width: 100%;
	height: 4px;
	background-color: #fff;
	display: block;
	margin-top: 5px;
	border-radius: 3px !important;
	-webkit-border-radius: 3px !important;
	position: absolute;
	bottom: 0px;
} */
.the-fumigation-process .lower p {
  padding-top: 8px;
}
#section10 .bottom-border h1:after {
  display: none;
}
/* -------------------------------------------------------------------------------------------------------------------------------
MEDIA QUERIES
-------------------------------------------------------------------------------------------------------------------------------  */
/* ----------------------------------------------------------------
Shell / Global
---------------------------------------------------------------- */

@media (min-width: 1400px) {
  img.hover-compare.activeCompare {
    right: 0px;
  }
}
@media (max-height: 769px) {
  .the-fumigation-process .lower h2 {
    font-size: 16px;
    line-height: 18px;
    height: 99px;
  }
}
@media (max-width: 1270px) {
  .touch .the-svg svg {
    overflow: visible !important;
    width: 70%;
    margin-left: 15%;
    position: relative;
  }
  .the-svg,
  .the-svg svg {
    overflow: visible !important;
  }
}
@media (max-width: 1270px) {
  .touch .the-svg svg {
    width: 100%;
    height: 90%;
    margin-left: 0%;
    margin-top: 2.5%;
  }
}
@media (max-width: 1000px) {
  .touch .the-svg svg {
    width: 100%;
    height: 60%;
    margin-left: 0%;
    margin-top: 20%;
  }
}
.touch img.hover-compare.activeCompare {
  display: none !important;
}
@media (max-height: 780px) and (min-width: 769px) {
  .midP .container,
  .bottomP .container {
    transform: scale(0.88);
    -moz-transform: scale(0.88);
    -ms-transform: scale(0.88);
    -o-transform: scale(0.88);
  }
  .family-ready .third .vertical-align-outer {
    height: 300px;
  }
}
@media (min-width: 1271px) {
  .video-comment p {
    padding-right: 30px;
  }
}
@media (min-width: 1300px) {
  .the-fumigation-process .lower h2 {
    height: 135px;
  }
}
@media (max-width: 1200px) {
  .reveal-slider .right-inner {
    transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
  }
  .reveal-slider {
    width: 1200px;
    margin-left: -600px;
  }
}
.how-to-prepare-carousel .fp-tableCell {
  height: auto !important;
}

#section10 .button-contain {
  position: relative;
  width: auto;
  display: inline-block;
}

.footer-center {
  text-align: center;
}

.cd-handle {
  border: none;
  width: auto;
  height: auto;
  background-color: transparent !important;
}

iframe,
object,
.videoWrapper span {
  width: 100% !important;
  min-width: 100% !important;
  position: relative;
}

.videoWrapper,
.video,
.videoWrapper span {
  position: relative;
}

@media (min-width: 768px) {
  .col-sm-7.video {
    height: 426px !important;
    padding: 5px;
    background-color: #fff;
    margin-top: 2px;
  }
  .video-comment {
    margin-top: 2px;
  }
}

span.cd-handle {
  margin-left: -19px;
}

.ie8.ie8-container {
  display: block;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  position: fixed;
  background-color: white;
}

.ie8.ie8-container p {
  background-color: #9d4e9e;
  padding: 15px;
  color: #f2f2f2;
}

.ie8.ie8-container p a {
  color: #fff;
  text-transform: uppercase;
  margin-left: 10px;
  padding-bottom: 2px;
  border-bottom: 2px solid #fff;
}

.ie8.ie8-container p a:hover {
  text-decoration: none;
}

.ie8-img-container img {
  width: 100%;
  height: 100%;
}

.ie8-img-container {
  height: 100%;
}

.button-contain a:hover,
.button-contain a:focus,
.button-contain a:active .htpb a:hover,
.htpb a:active,
.htpb a:focus {
  text-decoration: none;
}

@media (max-width: 1024px) {
  @-moz-document url-prefix() {
    .reveal-slider .left {
      left: 5%;
      width: 60%;
      padding-top: 140px !important;
    }
    .reveal-slider .left {
      left: 5%;
      width: 60%;
      padding-top: 140px !important;
    }
  }
}

/* ----------------------------------------------------------------
Need to merge
---------------------------------------------------------------- */

.bad-droid .fp-tableCell {
  vertical-align: middle;
}
.bad-droid div#section0 {
  padding-top: 0;
}
.bad-droid .leftOne img,
.bad-droid .rightOne img {
  width: 100%;
}
.bad-droid .leftOne {
  position: relative;
  left: -10px;
}
.bad-droid .rightOne {
  position: relative;
  top: 5px;
  right: -31px;
  width: 47%;
}
.bad-droid p.subhead.mobile {
  margin-top: 4px;
}
.not-android {
  display: none;
}
.bad-droid .how-it-works-mobile-blocks.mobile {
  width: 100%;
  margin: 0 auto;
}
.bad-droid .leftOne,
.bad-droid .rightOne {
  border-right: none;
  width: 45%;
  padding-right: 0px;
  display: inline-block;
}
.bad-droid .leftOne:after {
  content: '';
  width: 4px;
  height: 89%;
  background-color: #fff;
  position: absolute;
  top: 21px;
  left: 100%;
  margin-left: 22px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

@media (min-width: 768px) {
  /* Droid Tablet Styles */
  .bad-droid img.subhead-mob.mobile-svg {
    max-width: 80%;
  }

  .bad-droid p.subhead.mobile {
    text-align: center;
  }

  .bad-droid p.hero-content-btm.mobile {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .bad-droid .leftOne:after {
    content: '';
    width: 8px;
    height: 96%;
    background-color: #fff;
    position: absolute;
    top: 21px;
    left: 99%;
    margin-left: 22px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
  }

  .bad-droid .mobile.video-excerpt {
    display: none !important;
  }

  .bad-droid img.how-it-works-mobile-blocks.mobile.mobile-svg {
    width: 70%;
  }

  .bad-droid .how-to-prepare-carousel.mobile {
    width: 100% !important;
  }

  .bad-droid #section0 img.mobile-svg {
    height: 80vh;
    width: auto !important;
    max-width: 550px !important;
  }

  .bad-droid #section0 span.not-android {
    margin: 0 auto !important;
    text-align: center;
  }

  .bad-droid #section1 span.not-android {
    max-height: 90vh !important;
    text-align: center;
    margin: 0 auto !important;
  }

  .bad-droid #section1 img.headline.mobile.mobile-svg,
  .bad-droid #section4 img.headline.mobile.mobile-svg,
  .bad-droid #section7 img.headline.mobile {
    height: 16vh !important;
  }

  .bad-droid #section1 img.subhead-mob.mobile-svg,
  .bad-droid #section4 img.subhead-mob.mobile-svg,
  .bad-droid #section7 img.subhead-mob {
    height: 3vh;
  }

  .bad-droid #section1 .outer-drop.mobile.wf-outer,
  .bad-droid #section4 img.how-it-works-mobile-blocks.mobile.mobile-svg,
  .bad-droid #section7 img.how-it-works-mobile-blocks.mobile {
    width: 61%;
    min-width: 366px !important;
  }

  .bad-droid #section4 img.how-it-works-mobile-blocks.mobile.mobile-svg {
    width: 46%;
    min-width: 200px !important;
    margin-top: -20px !important;
  }

  .bad-droid img.carousel-object.not-android {
    width: 67% !important;
  }

  .bad-droid .carousel-control {
    font-size: 76px !important;
    position: absolute;
    top: 41%;
    margin-left: -33px !important;
    background: none !important;
  }

  .bad-droid .carousel-control.right {
    margin-right: -34px !important;
  }

  .bad-droid img.carousel-object.not-android {
    padding: 0px 51px;
  }

  .bad-droid #section7 .container {
    vertical-align: middle;
    display: table-cell;
    width: 100% !important;
  }

  .bad-droid div#section7 {
    vertical-align: middle;
    display: table;
    width: 100% !important;
  }

  .bad-droid button.learn-more.pdf-download.mobile {
    display: none !important;
  }

  .bad-droid .termites-panel.active-termites {
    display: none !important;
  }

  .bad-droid img.mobile.termite-mob {
    width: 130px !important;
    margin-bottom: 24px !important;
    height: 116px !important;
    min-width: 130px !important;
    max-height: 116px !important;
  }
}

/*right nav bullets*/
#fp-nav.right {
  right: 0;
}

#fp-nav.right ul {
  margin-top: -88px;
}

#fp-nav ul li a {
  border-radius: 50%;
  padding: 0;
  cursor: default;
}

#fp-nav ul li a span,
#fp-nav ul li:hover a span {
  background: rgba(170, 170, 170, 0.74);
  border: #aaa;
  width: 8px;
  height: 8px;
  margin: -4px 0 0 -4px;
}
#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span {
  background: #fff;
  border: #518ba2 solid 3px;
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
}
